import React from "react";
import {
  ArrowDown,
  ToggleOff,
  ToggleOn,
  Trash2Fill,
} from "react-bootstrap-icons";
import {
  OptionsMenu,
  OptionsMenuButton,
} from "../../../components/options-menu/OptionsMenu";
import { useConfirmation } from "../../../components/confirm-dialog/ConfirmationContext";
import { styled } from "styled-components";

export interface WallpaperListOptions {
  sortBy: "recency" | "views" | "downloads" | "conversion";
}

interface Props {
  options: WallpaperListOptions;
  isCollectionShown: boolean;

  onToggleSort: () => void;
  onToggleCollectionVisibility: () => void;
  onClickDelete: () => void;
}

export const defaultWallpaperListOptions: WallpaperListOptions = {
  sortBy: "recency",
};

export const nextSortBy = (sortBy: WallpaperListOptions["sortBy"]) => {
  switch (sortBy) {
    case "recency":
      return "views";
    case "views":
      return "downloads";
    case "downloads":
      return "conversion";
    case "conversion":
      return "recency";
  }
};

const StyledOptionsMenu = styled(OptionsMenu)`
  justify-content: space-between;
`;

const OptionsGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const WallpaperListOptionsMenu: React.FC<Props> = (props) => {
  const confirmation = useConfirmation();

  const sortButtonTitle = (() => {
    switch (props.options.sortBy) {
      case "recency":
        return "Sort by: Recency";
      case "views":
        return "Sort by: Views";
      case "downloads":
        return "Sort by: Downloads";
      case "conversion":
        return "Sort by: Conversion";
    }
  })();

  const onClickDelete = async () => {
    const result = await confirmation.confirm(
      "Are you sure?",
      "Delete collection"
    );
    if (result) {
      props.onClickDelete();
    }
  };

  return (
    <StyledOptionsMenu>
      <OptionsGroup>
        <OptionsMenuButton
          onClick={props.onToggleCollectionVisibility}
          $isSelected={props.isCollectionShown}
        >
          {props.isCollectionShown ? (
            <ToggleOn size={16} />
          ) : (
            <ToggleOff size={16} />
          )}

          {props.isCollectionShown
            ? "Collection is visible"
            : "Collection is hidden"}
        </OptionsMenuButton>
        <OptionsMenuButton $isDestructive onClick={onClickDelete}>
          <Trash2Fill size={12} />
          Delete
        </OptionsMenuButton>
      </OptionsGroup>

      <OptionsGroup>
        <OptionsMenuButton onClick={props.onToggleSort}>
          <ArrowDown size={12} />
          {sortButtonTitle}
        </OptionsMenuButton>
      </OptionsGroup>
    </StyledOptionsMenu>
  );
};

export default WallpaperListOptionsMenu;
