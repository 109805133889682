import styled from "styled-components";
import { breakpoints } from "../../styles/theme";

const Container = styled.div`
  width: 100%;
  max-width: 824px; // Three columns
  margin: 0 auto;
  padding-bottom: 64px;

  @media (max-width: ${breakpoints.desktop}) {
    max-width: 544px; // Two columns
  }

  @media (max-width: ${breakpoints.tablet}) {
    max-width: 367px; // Single column
  }

  @media (max-width: ${breakpoints.mobile}) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

export default Container;
