import {
  EyeFill,
  EyeSlashFill,
  RocketTakeoffFill,
  Trash2Fill,
} from "react-bootstrap-icons";
import { PopupMenu } from "../../../../components/menu-popover/PopupMenu";
import { useCollectionActions } from "./use-collection-actions";
import { PopupMenuButton } from "../../../../components/menu-popover/PopupMenuButton";
import { ReactNode } from "react";

interface Props {
  collectionId: string;
  isCollectionHidden: boolean;
  onCollectionUpdated: () => void;
}

type MenuContent = (closeMenu: () => void) => ReactNode;

export const CollectionCardActions: React.FC<Props> = (props) => {
  const {
    handleToggleHidden,
    handlePromote,
    handleDelete,
    isUpdatingHiddenState,
  } = useCollectionActions(props);

  const content: MenuContent = (closeMenu) => {
    const onToggleHidden = () => {
      handleToggleHidden(closeMenu);
    };

    const onPromote = () => {
      handlePromote(closeMenu);
    };

    const onDelete = () => {
      handleDelete(closeMenu);
    };

    return (
      <>
        <PopupMenuButton
          isLoading={isUpdatingHiddenState}
          onClick={onToggleHidden}
        >
          {props.isCollectionHidden ? (
            <EyeFill size={12} />
          ) : (
            <EyeSlashFill size={12} />
          )}
          {props.isCollectionHidden ? "Show" : "Hide"}
        </PopupMenuButton>

        <PopupMenuButton onClick={onPromote}>
          <RocketTakeoffFill size={12} />
          Promote
        </PopupMenuButton>

        <PopupMenuButton isDestructive={true} onClick={onDelete}>
          <Trash2Fill size={12} />
          Delete
        </PopupMenuButton>
      </>
    );
  };

  return <PopupMenu>{content}</PopupMenu>;
};
