import React from "react";
import GlobalStyles from "./styles/GlobalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import { CollectionsPage } from "./pages/collections/CollectionsPage";
import Container from "./components/page-structure/Container";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PromotionModal from "./pages/modals/promotion/PromotionModal";
import CollectionDetailsPage from "./pages/collection-details/CollectionDetailsPage";
import PromotionsPage from "./pages/promotions/PromotionsPage";
import { ConfirmationProvider } from "./components/confirm-dialog/ConfirmationContext";
import { WallpaperPreviewModal } from "./pages/modals/wallpaper-preview/WallpaperPreviewModal";
import PrivateRoute from "./components/auth/PrivateRoute";
import { AuthProvider } from "./components/auth/AuthContext";
import { LoginPage } from "./pages/login/LoginPage";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <ConfirmationProvider>
        <GlobalStyles />
        <Router>
          <AuthProvider>
            <Container>
              <Routes>
                {/* Authorization */}
                <Route path="/login" element={<LoginPage />} />

                {/* Collection and promoting collection */}
                <Route
                  path="/"
                  element={<PrivateRoute element={<CollectionsPage />} />}
                >
                  <Route
                    path="/collections/:collectionId/promote"
                    element={<PromotionModal />}
                  />
                </Route>

                {/* Collection details & wallpaper preview */}
                <Route
                  path="/collections/:collectionId"
                  element={<PrivateRoute element={<CollectionDetailsPage />} />}
                >
                  <Route
                    path="/collections/:collectionId/wallpapers/:wallpaperId"
                    element={<WallpaperPreviewModal />}
                  />
                </Route>

                {/* Promotions */}
                <Route
                  path="/promotions"
                  element={<PrivateRoute element={<PromotionsPage />} />}
                >
                  <Route
                    path="/promotions/:promotionId"
                    element={<PromotionModal />}
                  />
                </Route>
              </Routes>
            </Container>
          </AuthProvider>
        </Router>
      </ConfirmationProvider>
    </ThemeProvider>
  );
};

export default App;
