import { Wallpaper } from "../models/wallpaper";
import { Collection } from "../models/collection";
import apiClient from "../common/axios-instance";

const fetchAll = async (collectionId: string): Promise<Wallpaper.Model[]> => {
  const endpoint = `/collections/${collectionId}/wallpapers/all`;
  return apiClient
    .get<Wallpaper.Model[]>(endpoint)
    .then((response) => response.data);
};

const fetchByIds = async (ids: string[]): Promise<Wallpaper.Model[]> => {
  const endpoint = `/wallpapers/${ids.join(",")}`;
  return apiClient
    .get<Wallpaper.Model[]>(endpoint)
    .then((response) => response.data);
};

const createWithPresignedUploadUrl = async (
  collectionId: string
): Promise<PresignedUploadData> => {
  const endpoint = `/collections/${collectionId}/wallpapers/upload`;
  const response = await apiClient.post<PresignedUploadData>(endpoint);
  return response.data;
};

const update = async (
  id: Wallpaper.Id,
  updatedWallpaper: Partial<Wallpaper.Model>
): Promise<Wallpaper.Model> => {
  const endpoint = `/wallpapers/${id}`;
  const response = await apiClient.put<Wallpaper.Model>(
    endpoint,
    updatedWallpaper
  );
  return response.data;
};

const drop = async (args: {
  collectionId: Collection.Id;
  wallpaperId: Wallpaper.Id;
}): Promise<void> => {
  const endpoint = `/collections/${args.collectionId}/wallpapers/${args.wallpaperId}`;
  await apiClient.delete(endpoint);
};

export interface PresignedUploadData {
  wallpaper_id: string;
  presigned_upload_url: string;
}

export const WallpaperApiClient = {
  fetchAll,
  fetchByIds,
  createWithPresignedUploadUrl,
  update,
  drop,
};
