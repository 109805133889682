import React from "react";
import styled from "styled-components";
import ModalContainer from "../page-structure/ModalContainer";

interface ConfirmDialogProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
  buttonTitle: string;
}

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -1.5px;
  margin-right: 80px;
  margin-bottom: 36px;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 8px;
`;

const Button = styled.button<{ $isDestructive?: boolean }>`
  padding: 10px 20px;
  border-radius: 3vmin;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  color: black;

  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  background-color: ${(props) =>
    props.$isDestructive ? "#E74C3C" : "#E5E9E8"};
  color: ${(props) => (props.$isDestructive ? "#fff" : "#767877")};

  &:hover {
    background-color: ${(props) =>
      props.$isDestructive ? "#c44133" : "#d1d7d6"};
  }
`;

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  onConfirm,
  onCancel,
  message,
  buttonTitle,
}) => {
  if (!isOpen) return null;

  return (
    <ModalContainer closable maxWidth="512px" onClose={onCancel}>
      <Title>{message}</Title>
      <ActionsContainer>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm} $isDestructive>
          {buttonTitle}
        </Button>
      </ActionsContainer>
    </ModalContainer>
  );
};

export default ConfirmDialog;
