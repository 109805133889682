const theme = {
  colors: {
    background: "#F4F8F6",
    hoverShadow: "#181E1B1A",
    primary: "#151E1D",
    secondary: "#A9AEAF",
  },
};

const breakpoints = {
  mobile: "327px",
  tablet: "584px",
  desktop: "904px",
};

export { theme, breakpoints };
