import React from "react";
import { X } from "react-bootstrap-icons";
import styled, { keyframes } from "styled-components";

interface Props {
  maxWidth?: string;
  closable?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const zoomIn = keyframes`
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 0.2s ease-out;
`;

const ModalContent = styled.div<{ $maxWidth?: string }>`
  background: #fff;
  margin: 32px;
  padding: 40px 36px 36px 36px;
  border-radius: 16px;
  position: relative;
  max-width: ${(props) => props.$maxWidth || "748px"};
  width: 100%;
  animation: ${zoomIn} 0.15s ease-out;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px;
  background: none;
  border: none;
  cursor: pointer;
`;

const ModalContainer: React.FC<Props> = (props) => {
  return (
    <ModalBackdrop onClick={props.onClose}>
      <ModalContent
        $maxWidth={props.maxWidth}
        onClick={(e) => e.stopPropagation()}
      >
        {props.closable && (
          <CloseButton onClick={props.onClose}>
            <X size={16} />
          </CloseButton>
        )}
        {props.children}
      </ModalContent>
    </ModalBackdrop>
  );
};

export default ModalContainer;
