import { styled } from "styled-components";
import CardContainer from "../../../components/cards/CardContainer";
import { Tag, TagHighlightStyle } from "../../../components/tag/Tag";
import { CloudArrowDownFill, EyeFill, Fire } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { WallpaperCardActions } from "./actions/WallpaperCardActions";
import WallpaperPreviewImage from "./WallpaperPreviewImage";

interface Props {
  model: WallpaperCardModel;
  onWallpaperUpdated: () => void;
}

export interface WallpaperCardModel {
  id: string;
  collectionId: string;
  linkTo: string;
  image: string;

  views: string;
  viewsHighlightStyle?: TagHighlightStyle;

  downloads: string;
  downloadsHighlightStyle?: TagHighlightStyle;

  conversion: string;
  conversionHighlightStyle?: TagHighlightStyle;
}

const StyledCard = styled(CardContainer)`
  padding: 16px 24px 24px 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 8px;
`;

const TagsList = styled.ul<{ $isMuted?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  opacity: ${(props) => (props.$isMuted ? 0.5 : 1)};

  li {
    list-style: none;
  }
`;

export const WallpaperCard: React.FC<Props> = (props) => {
  return (
    <StyledCard as={Link} to={props.model.linkTo}>
      <Header>
        <WallpaperCardActions
          collectionId={props.model.collectionId}
          wallpaperId={props.model.id}
          onWallpaperUpdated={props.onWallpaperUpdated}
        />
      </Header>

      <WallpaperPreviewImage url={props.model.image} />

      <TagsList>
        <li>
          <Tag
            highlightStyle={props.model.viewsHighlightStyle}
            tooltipText="Views"
          >
            <EyeFill size={14} />
            <span>{props.model.views}</span>
          </Tag>
        </li>

        <li>
          <Tag
            highlightStyle={props.model.downloadsHighlightStyle}
            tooltipText="Downloads"
          >
            <CloudArrowDownFill size={14} />
            <span>{props.model.downloads}</span>
          </Tag>
        </li>

        <li>
          <Tag
            highlightStyle={props.model.conversionHighlightStyle}
            tooltipText="Conversion"
          >
            <Fire size={14} />
            <span>{props.model.conversion}</span>
          </Tag>
        </li>
      </TagsList>
    </StyledCard>
  );
};
