import { Collection } from "../../../api/models/collection";
import {
  Stats,
  collectStats,
  resolveHighlightStyle,
} from "../../../utils/analytics-highlightment-resolver";
import { CollectionCardModel } from "../components/CollectionCard";

const mapCollectionToCardProps = (
  collection: Collection.Model,
  stats: Stats
): CollectionCardModel => {
  let viewsHighlightStyle = undefined;
  let downloadsHighlightStyle = undefined;
  let conversionHighlightStyle = undefined;

  if (collection.is_explorable) {
    viewsHighlightStyle = resolveHighlightStyle(
      collection.analytics.views,
      stats.viewsThreshold
    );
    downloadsHighlightStyle = resolveHighlightStyle(
      collection.analytics.downloads,
      stats.downloadsThreshold
    );
    conversionHighlightStyle = resolveHighlightStyle(
      collection.analytics.conversion,
      stats.conversionThreshold
    );
  }

  return {
    id: collection.id,
    title: collection.metadata.title.en,
    isHidden: !collection.is_explorable,
    views: collection.analytics.views.toString(),
    viewsHighlightStyle,
    downloads: collection.analytics.downloads.toString(),
    downloadsHighlightStyle,
    conversion: `${Math.floor(collection.analytics.conversion * 100)}%`,
    conversionHighlightStyle,
    linkTo: `/collections/${collection.id}`,
  };
};

export const makeCardModels = (
  collections: Collection.Model[]
): CollectionCardModel[] => {
  const explorableCollections = collections.filter((c) => c.is_explorable);
  const stats = collectStats(explorableCollections);
  return collections.map((c) => mapCollectionToCardProps(c, stats));
};
