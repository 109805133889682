import { S3ApiClient } from "../clients/s3-api-client";
import { WallpaperApiClient } from "../clients/wallpapers-api-client";

const upload = async (
  collectionId: string,
  shouldBeExplorable: boolean,
  file: File,
  onUploadProgress?: (progress: number) => void
): Promise<void> => {
  onUploadProgress?.(0);

  const presignedData = await WallpaperApiClient.createWithPresignedUploadUrl(
    collectionId
  );

  await S3ApiClient.putToS3(
    presignedData.presigned_upload_url,
    file,
    onUploadProgress
  );

  if (shouldBeExplorable) {
    await WallpaperApiClient.update(presignedData.wallpaper_id, {
      is_explorable: true,
    });
  }
};

const uploadMultiple = async (
  collectionId: string,
  shouldBeExplorable: boolean,
  files: File[],
  onUploadProgress?: (progress: number) => void
): Promise<void> => {
  let allProgress: number[] = files.map(() => 0);

  const onSingleUploadProgress = (index: number, progress: number) => {
    allProgress[index] = progress;
    if (onUploadProgress) {
      onUploadProgress(
        allProgress.reduce((a, b) => a + b, 0) / allProgress.length
      );
    }
  };

  const uploadTasks = files.map((file, index) => {
    return upload(collectionId, shouldBeExplorable, file, (progress) =>
      onSingleUploadProgress(index, progress)
    );
  });

  await Promise.all(uploadTasks);
};

export const WallpaperUploader = {
  upload,
  uploadMultiple,
};
