import styled, { keyframes } from "styled-components";

const blink = keyframes`
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
`;

export const CardSkeleton = styled.div`
  background-color: #e8ebed;
  border-radius: 16px;
  animation: ${blink} 1.5s infinite;
`;
