import { useCallback, useEffect, useState } from "react";
import {
  Language,
  LocalizedValue,
  areEqualLocalizedValues,
} from "../../../../api/models/common";
import { Collection } from "../../../../api/models/collection";
import { CollectionApiClient } from "../../../../api/clients/collections-api-client";

const useTitleForm = (collection?: Collection.Model) => {
  const originalTitle = collection?.metadata?.title;
  const [localizedTitle, setLocalizedTitle] = useState<
    LocalizedValue<string> | undefined
  >(originalTitle);
  const [language, setLanguage] = useState<Language>(Language.en);
  const [isSaving, setIsSaving] = useState(false);

  if (originalTitle && localizedTitle === undefined) {
    setLocalizedTitle(originalTitle);
  }

  const onTitleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const prev = localizedTitle || { en: "" };
    setLocalizedTitle({ ...prev, [language]: e.target.value || undefined });

    if (!isSaving) {
      setIsSaving(true);
    }
  };

  const updateTitle = useCallback(
    async (newTitle?: LocalizedValue<string>) => {
      if (!newTitle || !collection) {
        return;
      }

      try {
        await CollectionApiClient.update(collection.id, {
          metadata: {
            ...collection.metadata,
            title: newTitle,
          },
        });
      } catch (error) {
        console.error("Error updating collection:", error);
      }
    },
    [collection]
  );

  useEffect(() => {
    if (
      !localizedTitle ||
      !collection ||
      areEqualLocalizedValues(localizedTitle, collection.metadata?.title)
    ) {
      return;
    }

    const handler = setTimeout(async () => {
      await updateTitle(localizedTitle);
      setIsSaving(false);
    }, 2000);

    return () => clearTimeout(handler);
  }, [collection, localizedTitle, updateTitle]);

  const title = localizedTitle?.[language] || "";
  const isLanguageDefined = (language: Language) =>
    !!localizedTitle?.[language];

  return {
    language,
    setLanguage,
    title,
    isLanguageDefined,
    onTitleChanged,
    isSaving,
  };
};

export default useTitleForm;
