interface CircularProgressBarProps {
  progress: number;
  size: number;
  radius: number;
  backgroundCircleColor: string;
  strokeWidth: number;
  strokeColor: string;
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  progress,
  size,
  radius,
  backgroundCircleColor,
  strokeWidth,
  strokeColor,
}) => {
  const normalizedRadius = radius + strokeWidth * 2;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${normalizedRadius * 2} ${normalizedRadius * 2}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        stroke={backgroundCircleColor}
        cx={normalizedRadius}
        cy={normalizedRadius}
        r={radius}
        strokeWidth={strokeWidth}
        fill="transparent"
      />
      <circle
        stroke={strokeColor}
        cx={normalizedRadius}
        cy={normalizedRadius}
        r={radius}
        strokeWidth={strokeWidth}
        fill="transparent"
        strokeLinecap="round"
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={offset}
        transform={`rotate(-90 ${normalizedRadius} ${normalizedRadius})`}
      />
    </svg>
  );
};

export default CircularProgressBar;
