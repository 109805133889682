import Header from "../../components/page-structure/Header";
import NavigationBar from "../../components/page-structure/NavigationBar";
import { Outlet } from "react-router-dom";
import CollectionListOptionsMenu, {
  nextSortBy,
} from "./components/CollectionListOptionsMenu";
import useCollections from "./hooks/use-collections";
import CollectionsList from "./components/CollectionsList";

export const CollectionsPage = () => {
  const { collections, loading, options, setOptions, reloadCollections } =
    useCollections();

  const toggleShowHiddenCollections = () => {
    setOptions((prev) => ({
      ...prev,
      showHiddenCollections: !prev.showHiddenCollections,
    }));
  };

  const toggleSort = () => {
    setOptions((prev) => ({
      ...prev,
      sortBy: nextSortBy(prev.sortBy),
    }));
  };

  return (
    <>
      <Header />
      <NavigationBar />
      <CollectionListOptionsMenu
        options={options}
        onToggleShowHiddenCollections={toggleShowHiddenCollections}
        onToggleSort={toggleSort}
      />
      <CollectionsList
        loading={loading}
        collections={collections}
        onCollectionUpdated={reloadCollections}
      />
      <Outlet />
    </>
  );
};
