import styled from "styled-components";

const CardContainer = styled.div`
  display: block;
  color: ${(props) => props.theme.colors.primary};
  background-color: #fff;
  border-radius: 16px;
  transition: box-shadow 0.2s ease-out;

  &:hover {
    box-shadow: 0px 20px 40px ${(props) => props.theme.colors.hoverShadow};
  }
`;

export default CardContainer;
