import { CardSkeleton } from "../../../components/cards/CardSkeleton";
import CardsList from "../../../components/cards/CardsList";
import AddCollectionCard from "./AddCollectionCard";
import { CollectionCard, CollectionCardModel } from "./CollectionCard";

interface Props {
  loading: boolean;
  collections: CollectionCardModel[];
  onCollectionUpdated: () => void;
}

const SkeletonContent: React.FC = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <li key={index}>
          <CardSkeleton style={{ width: "100%", height: "128px" }} />
        </li>
      ))}
    </>
  );
};

const CollectionsList: React.FC<Props> = (props) => {
  return (
    <main>
      <CardsList columnStyle="threeColumns">
        {props.loading ? (
          <SkeletonContent />
        ) : (
          <>
            <li key="add-collection" style={{ display: "flex" }}>
              <AddCollectionCard />
            </li>
            {props.collections.map((collection) => (
              <li key={collection.id}>
                <CollectionCard
                  model={collection}
                  onCollectionUpdated={props.onCollectionUpdated}
                />
              </li>
            ))}
          </>
        )}
      </CardsList>
    </main>
  );
};

export default CollectionsList;
