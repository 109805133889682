import { CardSkeleton } from "../../../components/cards/CardSkeleton";
import CardsList from "../../../components/cards/CardsList";
import { PromotionCard, PromotionCardModel } from "./PromotionCard";

interface Props {
  loading: boolean;
  promotions: PromotionCardModel[];
  onPromotionUpdated: () => void;
}

const SkeletonContent: React.FC = () => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <li key={index}>
          <CardSkeleton style={{ width: "100%", height: "237px" }} />
        </li>
      ))}
    </>
  );
};

const PromotionsList: React.FC<Props> = (props) => {
  return (
    <main>
      <CardsList columnStyle="twoColumns">
        {props.loading ? (
          <SkeletonContent />
        ) : (
          <>
            {props.promotions.map((promotion) => (
              <li key={promotion.id}>
                <PromotionCard
                  model={promotion}
                  onPromotionUpdated={props.onPromotionUpdated}
                />
              </li>
            ))}
          </>
        )}
      </CardsList>
    </main>
  );
};

export default PromotionsList;
