import { PlusCircleFill, Trash2Fill } from "react-bootstrap-icons";
import { PopupMenuButton } from "../../../../../components/menu-popover/PopupMenuButton";

interface Props {
  onCopy: () => void;
  onDelete: () => void;
}

export const WallpaperRootSubMenu: React.FC<Props> = (props) => (
  <>
    <PopupMenuButton onClick={props.onCopy}>
      <PlusCircleFill size={12} /> Add to...
    </PopupMenuButton>
    <PopupMenuButton isDestructive={true} onClick={props.onDelete}>
      <Trash2Fill size={12} />
      Delete
    </PopupMenuButton>
  </>
);
