import { PopupMenu } from "../../../../components/menu-popover/PopupMenu";
import useWallpaperActions from "./use-wallpaper-actions";
import { useEffect, useState } from "react";
import { WallpaperRootSubMenu } from "./submenus/WallpaperRootSubmenu";
import { WallpaperCopySubmenu } from "./submenus/WallpaperCopySubmenu";

interface Props {
  collectionId: string;
  wallpaperId: string;
  onWallpaperUpdated: () => void;
}

type SubMenu = "root" | "copy";

interface ContentProps extends Props {
  subMenu: SubMenu;
  closeMenu: () => void;
  onMenuOpen: () => void;
  onCopy: () => void;
  onDelete: () => void;
}

const MenuPopoverContent: React.FC<ContentProps> = (props) => {
  const { onMenuOpen, onCopy, onDelete, closeMenu, subMenu, wallpaperId } =
    props;

  // eslint-disable-next-line
  useEffect(onMenuOpen, []);

  switch (subMenu) {
    case "root":
      return <WallpaperRootSubMenu onCopy={onCopy} onDelete={onDelete} />;
    case "copy":
      return (
        <WallpaperCopySubmenu wallpaperId={wallpaperId} closeMenu={closeMenu} />
      );
  }
};

export const WallpaperCardActions: React.FC<Props> = (props) => {
  const { handleDelete } = useWallpaperActions(props);
  const [submenu, setSubmenu] = useState<SubMenu>("root");

  const onMenuOpen = () => {
    setSubmenu("root");
  };

  const onCopy = () => {
    setSubmenu("copy");
  };

  const onDelete = (closeMenu: () => void) => {
    handleDelete(closeMenu);
  };

  return (
    <PopupMenu>
      {(closeMenu) => (
        <MenuPopoverContent
          {...props}
          subMenu={submenu}
          closeMenu={closeMenu}
          onMenuOpen={onMenuOpen}
          onCopy={onCopy}
          onDelete={() => onDelete(closeMenu)}
        />
      )}
    </PopupMenu>
  );
};
