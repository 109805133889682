import { useCallback, useEffect, useState } from "react";
import { Language, LocalizedValue } from "../../../api/models/common";
import { useNavigate } from "react-router-dom";
import { PromotionsApiClient } from "../../../api/clients/promotions-api-client";
import { Promotion } from "../../../api/models/promotion";
import { CollectionApiClient } from "../../../api/clients/collections-api-client";

export const usePromotionModalForm = (params: {
  promotionId?: string;
  collectionId?: string;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const [language, setLanguage] = useState<Language>(Language.en);
  const [collectionId, setCollectionId] = useState<string | undefined>(
    params.collectionId
  );
  const [collectionTitle, setCollectionTitle] = useState<string>("");
  const [localizedTitle, setLocalizedTitle] = useState<LocalizedValue<string>>({
    en: "",
  });
  const [localizedDescription, setLocalizedDescription] = useState<
    LocalizedValue<string>
  >({
    en: "",
  });
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [sendPushDate, setSendPushDate] = useState<Date>(new Date());

  const title = localizedTitle[language] || "";
  const description = localizedDescription[language] || "";

  const loadCollection = useCallback(async (collectionId: string) => {
    try {
      setIsLoading(true);

      const collections = await CollectionApiClient.fetchByIds([collectionId]);
      setCollectionTitle(collections[0].metadata.title.en);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const loadPromotion = useCallback(
    async (promotionId: string) => {
      try {
        setIsLoading(true);

        const promotion = await PromotionsApiClient.get(promotionId);
        await loadCollection(promotion.collection_id);
        setCollectionId(promotion.collection_id);
        setLocalizedTitle(promotion.title);
        setLocalizedDescription(promotion.description);
        setStartDate(new Date(promotion.starts_at));
        setEndDate(new Date(promotion.ends_at));
        setSendPushDate(new Date(promotion.send_push_at));
      } catch (error) {
        alert("Error loading promotion. Please try again later.");
        console.error(error);
        navigate(-1);
      } finally {
        setIsLoading(false);
      }
    },
    [loadCollection, navigate]
  );

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!localizedTitle.en || !localizedDescription.en) {
      alert("Promotion title and description is required in English");
      return;
    }

    if (endDate < startDate) {
      alert("End date cannot be before start date");
      return;
    }

    if (sendPushDate < startDate || sendPushDate > endDate) {
      alert("Send push date cannot be before start date or after end date");
      return;
    }

    setIsSaving(true);

    try {
      await createOrUpdatePromotion(
        {
          title: localizedTitle,
          description: localizedDescription,
          starts_at: getDateTimestamp(startDate),
          ends_at: getDateTimestamp(endDate),
          send_push_at: sendPushDate.getTime(),
        },
        params.promotionId,
        collectionId
      );

      navigate("/promotions", {
        replace: true,
        state: { reload: true },
      });
    } catch (error) {
      console.error("Error creating promotion:", error);
      alert("Error creating promotion. Please try again later.");
    }

    setIsSaving(false);
  };

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalizedTitle({ ...localizedTitle, [language]: e.target.value });
  };

  const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalizedDescription({
      ...localizedDescription,
      [language]: e.target.value,
    });
  };

  useEffect(() => {
    if (params.promotionId) {
      loadPromotion(params.promotionId);
    }

    if (params.collectionId) {
      loadCollection(params.collectionId);
    }
  }, [params.promotionId, params.collectionId, loadPromotion, loadCollection]);

  const isLanguageDefined = (language: Language) =>
    !!localizedTitle[language] && !!localizedDescription[language];

  return {
    language,
    setLanguage,
    collectionTitle,
    title,
    onChangeTitle,
    description,
    onChangeDescription,
    isLanguageDefined,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    sendPushDate,
    setSendPushDate,
    isLoading,
    isSaving,
    onSubmit,
    onClose: () => navigate(-1),
  };
};

const getDateTimestamp = (date: Date) => {
  const timePortion = date.getTime() % (3600 * 1000 * 24);
  return date.getTime() - timePortion;
};

const createOrUpdatePromotion = async (
  promotion: Omit<Promotion, "id" | "collection_id">,
  promotionId?: string,
  collectionId?: string
) => {
  if (promotionId) {
    await PromotionsApiClient.update(promotionId, promotion);
    return;
  } else if (collectionId) {
    await PromotionsApiClient.create({
      ...promotion,
      collection_id: collectionId,
    });
  } else {
    throw new Error("Either promotionId or collectionId must be provided");
  }
};
