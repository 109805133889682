import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.primary};
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-variation-settings: "wdth" 100;
  }

  input, textarea, button {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    letter-spacing: -0.3px;
  }

  a {
    text-decoration: none;
  }
`;

export default GlobalStyles;
