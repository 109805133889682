const HIGHLIGHT_THRESHOLD_LOW = 10;
const HIGHLIGHT_THRESHOLD_HIGH = 90;

interface Item {
  analytics: {
    previews?: number;
    views?: number;
    downloads: number;
    conversion: number;
  };
}

const calculatePercentile = (data: number[], percentile: number): number => {
  const sorted = [...data].sort((a, b) => a - b);
  const index = Math.ceil((percentile / 100) * sorted.length) - 1;
  return sorted[index];
};
export const resolveHighlightStyle = (
  value: number,
  threshold: { low: number; high: number }
): "good" | "bad" | undefined => {
  if (value <= threshold.low) {
    return "bad";
  } else if (value >= threshold.high) {
    return "good";
  }
  return undefined;
};

export interface Stats {
  viewsThreshold: {
    low: number;
    high: number;
  };
  downloadsThreshold: {
    low: number;
    high: number;
  };
  conversionThreshold: {
    low: number;
    high: number;
  };
}

export const collectStats = (items: Item[]): Stats => {
  const views = items.map(
    (c) => c.analytics.previews || c.analytics.views || 0
  );
  const downloads = items.map((c) => c.analytics.downloads);
  const conversions = items.map((c) => c.analytics.conversion);

  return {
    viewsThreshold: {
      low: calculatePercentile(views, HIGHLIGHT_THRESHOLD_LOW),
      high: calculatePercentile(views, HIGHLIGHT_THRESHOLD_HIGH),
    },
    downloadsThreshold: {
      low: calculatePercentile(downloads, HIGHLIGHT_THRESHOLD_LOW),
      high: calculatePercentile(downloads, HIGHLIGHT_THRESHOLD_HIGH),
    },
    conversionThreshold: {
      low: calculatePercentile(conversions, HIGHLIGHT_THRESHOLD_LOW),
      high: calculatePercentile(conversions, HIGHLIGHT_THRESHOLD_HIGH),
    },
  };
};
