import React from "react";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledNavigationBar = styled.nav`
  margin-bottom: 24px;

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 32px;
  }
`;

const StyledNavLink = styled(NavLink)`
  font-size: 28px;
  letter-spacing: -1px;
  font-weight: bold;

  color: #bcc5c4;

  &.active,
  &[data-active="true"] {
    color: #151e1d;
  }

  &:hover {
    color: #7fa8a7;
  }

  &.active:hover,
  &[data-active="true"]:hover {
    color: #000;
  }

  transition: color 0.1s ease;
`;

const NavigationBar: React.FC = () => {
  const location = useLocation();

  const isActive = (paths: string[]): boolean => {
    return paths.some((path) =>
      matchPath({ path, end: true }, location.pathname)
    );
  };

  return (
    <StyledNavigationBar>
      <ul>
        <li>
          <StyledNavLink
            to="/"
            data-active={isActive(["/", "/collections/:id"])}
          >
            Collections
          </StyledNavLink>
        </li>
        <li>
          <StyledNavLink
            to="/promotions"
            data-active={isActive(["/promotions", "/promotions/:id"])}
          >
            Promotions
          </StyledNavLink>
        </li>
      </ul>
    </StyledNavigationBar>
  );
};

export default NavigationBar;
