import apiClient from "../common/axios-instance";
import { Promotion } from "../models/promotion";

const fetchAll = async (): Promise<Promotion[]> => {
  const endpoint = `/promotions`;
  const response = await apiClient.get<Promotion[]>(endpoint);
  return response.data;
};

const get = async (id: string): Promise<Promotion> => {
  const endpoint = `/promotions/${id}`;
  const response = await apiClient.get<Promotion>(endpoint);
  return response.data;
};

const create = async (
  newPromotion: Omit<Promotion, "id">
): Promise<Promotion> => {
  const endpoint = `/promotions`;
  const response = await apiClient.post<Promotion>(endpoint, newPromotion);
  return response.data;
};

const update = async (
  id: string,
  updatedPromotion: Partial<Promotion>
): Promise<Promotion> => {
  const endpoint = `/promotions/${id}`;
  const response = await apiClient.put<Promotion>(endpoint, updatedPromotion);
  return response.data;
};

const drop = async (id: string): Promise<void> => {
  const endpoint = `/promotions/${id}`;
  await apiClient.delete(endpoint);
};

export const PromotionsApiClient = { fetchAll, get, create, update, drop };
