import React from "react";
import { styled } from "styled-components";
import { ChevronDown } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerContainer = styled.div`
  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Open Sans", sans-serif;
    border: none;
    border-radius: 12px;
    box-shadow: 0 8px 24px rgba(26, 30, 29, 0.12);
  }

  .react-datepicker-popper .react-datepicker__triangle {
    stroke: #fff;
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    fill: #fff;
    color: #fff;
  }

  .react-datepicker__navigation {
    top: 12px;
  }

  .react-datepicker__day-names {
    margin-top: 12px;
  }

  .react-datepicker__header {
    background-color: #fff;
    border-bottom: #e8edf3 1px solid;
    border-radius: 12px 12px 0 0;
    padding: 16px 0 8px 0;
  }

  .react-datepicker__time-container {
    border-left: #e8edf3 1px solid;
  }

  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected {
    background-color: #2e554f !important;
    color: #fff;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #a1b5b3;
    color: #fff;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  padding: 24px;
  border-radius: 8px 12px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.primary};
  background: #f0f3f1;
  cursor: pointer;
`;

const MutedText = styled.span`
  color: #a9aeaf;
`;

const DateText = styled.span`
  color: ${(props) => props.theme.colors.primary};
  margin-left: 4px;
`;

interface DateInputProps {
  date: Date | null;
  prefix: string;
  timePickerEnabled?: boolean;
  onClick?: () => void;
}

const DateInput = React.forwardRef(
  (props: DateInputProps, ref: React.Ref<HTMLInputElement>) => {
    const formatDate = (date: Date | null) => {
      if (date) {
        return date.toLocaleDateString(undefined, {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: props.timePickerEnabled ? "2-digit" : undefined,
          minute: props.timePickerEnabled ? "2-digit" : undefined,
        });
      } else {
        return "...";
      }
    };

    return (
      <InputContainer onClick={props.onClick} ref={ref}>
        <div>
          <MutedText>{props.prefix}</MutedText>
          <DateText>{formatDate(props.date)}</DateText>
        </div>
        <ChevronDown size={16} />
      </InputContainer>
    );
  }
);

interface Props {
  prefix: string;
  date: Date | null;
  timePickerEnabled?: boolean;
  onChange: (date: Date | null) => void;
}

const DatePicker: React.FC<Props> = (props) => {
  const convertToUTC = (date: Date | null) => {
    if (date) {
      const offsetInMs = date.getTimezoneOffset() * 60000;
      return new Date(date.getTime() + offsetInMs);
    }
    return date;
  };

  const convertFromUTC = (date: Date | null) => {
    if (date) {
      const offsetInMs = date.getTimezoneOffset() * 60000;
      return new Date(date.getTime() - offsetInMs);
    }
    return date;
  };

  return (
    <DatePickerContainer>
      <ReactDatePicker
        selected={props.date ? convertToUTC(props.date) : null}
        onChange={(date) => {
          const utcDate = convertFromUTC(date);
          props.onChange(utcDate);
        }}
        calendarStartDay={1}
        showTimeSelect={props.timePickerEnabled}
        timeFormat="HH:mm"
        timeIntervals={30}
        dateFormat={
          props.timePickerEnabled ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"
        }
        customInput={
          <DateInput
            date={props.date ? convertToUTC(props.date) : null}
            prefix={props.prefix}
            onClick={() => {}}
            timePickerEnabled={props.timePickerEnabled}
          />
        }
      />
    </DatePickerContainer>
  );
};

export default DatePicker;
