import { Collection } from "../../../api/models/collection";
import { Promotion } from "../../../api/models/promotion";
import { PromotionCardModel } from "../components/PromotionCard";

const makePromotionCardModel = (
  promotion: Promotion,
  collection: Collection.Model
): PromotionCardModel => {
  const today = new Date();
  const pushDate = convertToUTC(new Date(promotion.send_push_at));
  const endDate = new Date(promotion.ends_at);

  const pushDateText =
    pushDate < today
      ? `Push sent ${formatDate(pushDate)}`
      : `Push will be sent ${formatDate(pushDate)}`;
  const pushDateHighlightStyle = pushDate < today ? "good" : undefined;

  const endDateText =
    endDate < today
      ? `Ended ${formatDate(endDate)}`
      : `Ends ${formatDate(endDate)}`;
  const endDateHighlightStyle = endDate < today ? "good" : undefined;

  return {
    id: promotion.id,
    collectionTitle: collection.metadata.title.en,
    title: promotion.title.en,
    description: promotion.description.en,
    linkTo: `/promotions/${promotion.id}`,
    pushDateText: pushDateText,
    pushDateHighlightStyle: pushDateHighlightStyle,
    endDateText: endDateText,
    endDateHighlightStyle: endDateHighlightStyle,
  };
};

const convertToUTC = (date: Date) => {
  const offsetInMs = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + offsetInMs);
};

const formatDate = (date: Date): string => {
  const today = new Date();
  const year = date.getFullYear();
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  if (year === today.getFullYear()) {
    return `${month} ${day}`;
  }
  return `${month} ${day}, ${year}`;
};

export default makePromotionCardModel;
