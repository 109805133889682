import { useState, useEffect } from "react";
import {
  CollectionListOptions,
  defaultCollectionListOptions,
} from "../components/CollectionListOptionsMenu";
import { CollectionApiClient } from "../../../api/clients/collections-api-client";
import { makeCardModels } from "./collection-model-factory";
import { Collection } from "../../../api/models/collection";
import { useLocation } from "react-router-dom";
import { applyOptions } from "./collection-options-applier";

const useCollections = () => {
  const [collections, setCollections] = useState<Collection.Model[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<CollectionListOptions>(
    defaultCollectionListOptions
  );
  const location = useLocation();

  const loadCollections = async () => {
    try {
      const collections = await CollectionApiClient.fetchAll({
        limit: 100,
      });
      setCollections(collections);
    } catch (error) {
      console.error("Error fetching collections:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    loadCollections();
  }, []);

  useEffect(() => {
    if (location.state?.reload) {
      loadCollections();
      location.state.reload = false;
    }

    if (location.state?.showHidden) {
      setOptions((prev) => ({ ...prev, showHiddenCollections: true }));
      location.state.showHidden = false;
    }
  }, [location.state]);

  return {
    collections: makeCardModels(applyOptions(collections, options)),
    loading: loading,
    options: options,
    setOptions: setOptions,
    reloadCollections: loadCollections,
  };
};

export default useCollections;
