import { useEffect, useState } from "react";
import { CollectionApiClient } from "../../../../../api/clients/collections-api-client";
import { Collection } from "../../../../../api/models/collection";

interface Props {
  wallpaperId: string;
  closeMenu: () => void;
}

export const useWallpaperCopySubmenu = (props: Props) => {
  const [collections, setCollections] = useState<Collection.Model[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const loadCollections = async () => {
    try {
      const collections = await CollectionApiClient.fetchAll({
        limit: 100,
      });
      collections.sort((a, b) =>
        a.metadata.title.en.localeCompare(b.metadata.title.en)
      );
      setCollections(collections);
    } catch (error) {
      console.error("Error fetching collections:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCollectionSelected = async (collectionId: string) => {
    try {
      setIsLoading(true);
      await CollectionApiClient.addWallpapers(collectionId, [
        props.wallpaperId,
      ]);
    } catch (error) {
      console.error("Error adding wallpaper to collection:", error);
      alert("Error adding wallpaper to collection. Please try again later.");
    } finally {
      props.closeMenu();

      // Wait for animation
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    loadCollections();
  }, []);

  return { collections, isLoading, handleCollectionSelected };
};
