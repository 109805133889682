import { CardSkeleton } from "../../../components/cards/CardSkeleton";
import CardsList from "../../../components/cards/CardsList";
import { WallpaperCard, WallpaperCardModel } from "./WallpaperCard";
import AddWallpaperCard from "./add-wallpaper-card/AddWallpaperCard";

interface Props {
  loading: boolean;
  collectionId: string | undefined;
  isExplorable: boolean;
  wallpapers: WallpaperCardModel[];
  onWallpapersUpdated: () => void;
}

const SkeletonContent: React.FC = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <li key={index}>
          <CardSkeleton style={{ width: "100%", height: "508px" }} />
        </li>
      ))}
    </>
  );
};

const WallpapersList: React.FC<Props> = (props) => {
  const isEmptyList = props.wallpapers.length === 0 && !props.loading;

  return (
    <main>
      <CardsList columnStyle={isEmptyList ? "singleColumn" : "threeColumns"}>
        {props.loading ? (
          <SkeletonContent />
        ) : (
          <>
            <li key="add-wallpaper" style={{ display: "flex" }}>
              <AddWallpaperCard
                collectionId={props.collectionId}
                isExplorable={props.isExplorable}
                onWallpapersUpdated={props.onWallpapersUpdated}
              />
            </li>

            {props.wallpapers.map((wallpaper) => (
              <li key={wallpaper.id}>
                <WallpaperCard
                  model={wallpaper}
                  onWallpaperUpdated={props.onWallpapersUpdated}
                />
              </li>
            ))}
          </>
        )}
      </CardsList>
    </main>
  );
};

export default WallpapersList;
