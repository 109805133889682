import { useState, useEffect, useCallback } from "react";
import { WallpaperApiClient } from "../../../api/clients/wallpapers-api-client";
import { makeCardModels } from "./wallpaper-model-factory";
import { Wallpaper } from "../../../api/models/wallpaper";
import { useLocation } from "react-router-dom";
import {
  WallpaperListOptions,
  defaultWallpaperListOptions,
  nextSortBy,
} from "../components/WallpaperListOptionsMenu";
import { applyOptions } from "./wallpaper-list-options-applier";

const useWallpapers = (collectionId?: string) => {
  const [wallpapers, setWallpapers] = useState<Wallpaper.Model[]>([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<WallpaperListOptions>(
    defaultWallpaperListOptions
  );
  const location = useLocation();
  const loadWallpapers = useCallback(async () => {
    if (!collectionId) return;

    try {
      const loadedWallpapers = await WallpaperApiClient.fetchAll(collectionId);
      setWallpapers(loadedWallpapers);
    } catch (error) {
      console.error("Error fetching wallpapers:", error);
    } finally {
      setLoading(false);
    }
  }, [collectionId]);

  useEffect(() => {
    setLoading(true);
    loadWallpapers();
  }, [loadWallpapers]);

  useEffect(() => {
    if (location.state?.reload) {
      loadWallpapers();
      location.state.reload = false;
    }
  }, [loadWallpapers, location.state]);

  const onToggleSort = () => {
    setOptions({ ...options, sortBy: nextSortBy(options.sortBy) });
  };

  const filteredWallpapers = applyOptions(wallpapers, options);
  const models =
    (collectionId && makeCardModels(filteredWallpapers, collectionId)) || [];

  return {
    loading: loading,
    options: options,
    wallpapers: models,
    reloadWallpapers: loadWallpapers,
    onToggleSort,
  };
};

export default useWallpapers;
