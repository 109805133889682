export interface Identifiable {
  id: string;
}

export enum Language {
  en = "en",
  ru = "ru",
  de = "de",
  es = "es",
  fr = "fr",
  it = "it",
}

export type LocalizedValue<T> = {
  [K in keyof typeof Language as Exclude<K, "en">]?: T;
} & {
  en: T;
};

export const LanguageNames: Record<Language, string> = {
  [Language.en]: "English",
  [Language.ru]: "Russian",
  [Language.de]: "German",
  [Language.es]: "Spanish",
  [Language.fr]: "French",
  [Language.it]: "Italian",
};

export function areEqualLocalizedValues<T>(
  a: LocalizedValue<T>,
  b: LocalizedValue<T>
): boolean {
  if (Object.keys(a).length !== Object.keys(b).length) {
    return false;
  }

  for (const [key, value] of Object.entries(a)) {
    if (value !== b[key as Language]) {
      return false;
    }
  }

  return true;
}
