import { useState } from "react";
import { CollectionApiClient } from "../../../../api/clients/collections-api-client";
import { useNavigate } from "react-router-dom";
import { useConfirmation } from "../../../../components/confirm-dialog/ConfirmationContext";

interface Props {
  collectionId: string;
  isCollectionHidden: boolean;
  onCollectionUpdated: () => void;
}

export const useCollectionActions = (props: Props) => {
  const [isUpdatingHiddenState, setUpdatingHiddenState] = useState(false);
  const confirmation = useConfirmation();
  const navigate = useNavigate();

  const handleToggleHidden = async (dismiss: () => void) => {
    const updatedValue = !props.isCollectionHidden;

    try {
      setUpdatingHiddenState(true);
      await CollectionApiClient.update(props.collectionId, {
        is_explorable: !updatedValue,
      });
      props.onCollectionUpdated();
    } catch (error) {
      console.error("Error updating collection:", error);
    } finally {
      setUpdatingHiddenState(false);
      dismiss();
    }
  };

  const handlePromote = (dismiss: () => void) => {
    dismiss();
    navigate(`/collections/${props.collectionId}/promote`);
  };

  const handleDelete = async (dismiss: () => void) => {
    dismiss();

    const confirmDelete = await confirmation.confirm(
      "Are you sure?",
      "Delete collection"
    );

    if (!confirmDelete) {
      return;
    }

    try {
      await CollectionApiClient.drop(props.collectionId);
      props.onCollectionUpdated();
    } catch (error) {
      console.error("Error deleting collection:", error);
      alert("Failed to delete collection.");
    }
  };

  return {
    handleToggleHidden,
    handlePromote,
    handleDelete,
    isUpdatingHiddenState,
  };
};
