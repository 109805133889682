import React from "react";
import Header from "../../components/page-structure/Header";
import NavigationBar from "../../components/page-structure/NavigationBar";
import WallpapersList from "./components/WallpapersList";
import useWallpapers from "./hooks/use-wallpapers";
import { Outlet, useParams } from "react-router-dom";
import useCollection from "./hooks/use-collection";
import CollectionTitleContainer from "./components/title-container/CollectionTitleContainer";
import WallpaperListOptionsMenu from "./components/WallpaperListOptionsMenu";

const CollectionDetailsPage: React.FC = () => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const collection = useCollection(collectionId);
  const wallpapers = useWallpapers(collectionId);

  return (
    <>
      <Header />
      <NavigationBar />
      <CollectionTitleContainer
        collection={collection.collection}
        onCollectionUpdated={collection.reloadCollection}
      />

      <WallpaperListOptionsMenu
        options={wallpapers.options}
        isCollectionShown={collection.collection?.is_explorable || false}
        onToggleSort={wallpapers.onToggleSort}
        onToggleCollectionVisibility={collection.toggleVisibility}
        onClickDelete={collection.deleteCollection}
      />

      <WallpapersList
        loading={wallpapers.loading}
        collectionId={collectionId}
        isExplorable={collection.collection?.is_explorable || false}
        wallpapers={wallpapers.wallpapers}
        onWallpapersUpdated={wallpapers.reloadWallpapers}
      />
      <Outlet />
    </>
  );
};

export default CollectionDetailsPage;
