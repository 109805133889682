import styled, { keyframes } from "styled-components";

const popUpAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px) translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
`;

const Tooltip = styled.div<{ $hostingComponent: string }>`
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 4px + 3px);
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 3px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  }

  ${(props) => props.$hostingComponent}:hover & {
    animation: ${popUpAnimation} 0.3s forwards;
    visibility: visible;
    opacity: 1;
  }
`;

export default Tooltip;
