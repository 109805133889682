import { Wallpaper } from "../../../api/models/wallpaper";
import {
  Stats,
  collectStats,
  resolveHighlightStyle,
} from "../../../utils/analytics-highlightment-resolver";
import { WallpaperCardModel } from "../components/WallpaperCard";

const mapWallpaperToCardProps = (
  wallpaper: Wallpaper.Model,
  collectionId: string,
  stats: Stats
): WallpaperCardModel => {
  const sizes = wallpaper.sizes;
  const imageUrl = sizes.w828?.public_url || sizes.original.public_url || "";

  if (!imageUrl) {
    console.log(`Missing image for ${wallpaper.id}`);
  }

  return {
    id: wallpaper.id,
    collectionId,
    linkTo: `/collections/${collectionId}/wallpapers/${wallpaper.id}`,
    image: imageUrl,
    views: wallpaper.analytics.previews.toString(),
    viewsHighlightStyle: resolveHighlightStyle(
      wallpaper.analytics.previews,
      stats.viewsThreshold
    ),
    downloads: wallpaper.analytics.downloads.toString(),
    downloadsHighlightStyle: resolveHighlightStyle(
      wallpaper.analytics.downloads,
      stats.downloadsThreshold
    ),
    conversion: `${Math.floor(wallpaper.analytics.conversion * 100)}%`,
    conversionHighlightStyle: resolveHighlightStyle(
      wallpaper.analytics.conversion,
      stats.conversionThreshold
    ),
  };
};

export const makeCardModels = (
  wallpapers: Wallpaper.Model[],
  collectionId: string
): WallpaperCardModel[] => {
  const stats = collectStats(wallpapers);
  return wallpapers.map((c) => mapWallpaperToCardProps(c, collectionId, stats));
};
