import React from "react";
import { ArrowDown, EyeSlashFill } from "react-bootstrap-icons";
import {
  OptionsMenu,
  OptionsMenuButton,
} from "../../../components/options-menu/OptionsMenu";

export interface CollectionListOptions {
  showHiddenCollections: boolean;
  sortBy: "recency" | "views" | "downloads" | "conversion";
}

interface Props {
  options: CollectionListOptions;
  onToggleShowHiddenCollections: () => void;
  onToggleSort: () => void;
}

export const defaultCollectionListOptions: CollectionListOptions = {
  showHiddenCollections: false,
  sortBy: "recency",
};

export const nextSortBy = (sortBy: CollectionListOptions["sortBy"]) => {
  switch (sortBy) {
    case "recency":
      return "views";
    case "views":
      return "downloads";
    case "downloads":
      return "conversion";
    case "conversion":
      return "recency";
  }
};

const CollectionListOptionsMenu: React.FC<Props> = (props) => {
  const sortButtonTitle = (() => {
    switch (props.options.sortBy) {
      case "recency":
        return "Sort by: Recency";
      case "views":
        return "Sort by: Views";
      case "downloads":
        return "Sort by: Downloads";
      case "conversion":
        return "Sort by: Conversion";
    }
  })();

  return (
    <OptionsMenu>
      <OptionsMenuButton onClick={props.onToggleSort}>
        <ArrowDown size={12} />
        {sortButtonTitle}
      </OptionsMenuButton>
      <OptionsMenuButton
        $isSelected={props.options.showHiddenCollections}
        onClick={props.onToggleShowHiddenCollections}
      >
        <EyeSlashFill size={12} />
        Show hidden
      </OptionsMenuButton>
    </OptionsMenu>
  );
};

export default CollectionListOptionsMenu;
