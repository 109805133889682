import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from "react";
import ConfirmDialog from "./ConfirmDialog";

interface ConfirmationContextType {
  confirm: (message: string, buttonTitle: string) => Promise<boolean>;
}

const ConfirmationContext = createContext<ConfirmationContextType | undefined>(
  undefined
);

export const useConfirmation = () => {
  const context = useContext(ConfirmationContext);
  if (!context) {
    throw new Error(
      "useConfirmation must be used within a ConfirmationProvider"
    );
  }
  return context;
};

interface ConfirmationProviderProps {
  children: ReactNode;
}

export const ConfirmationProvider: React.FC<ConfirmationProviderProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [buttonTitle, setButtonTitle] = useState("");
  const [resolveReject, setResolveReject] =
    useState<(value: boolean | PromiseLike<boolean>) => void>();

  const requestConfirm = (
    message: string,
    buttonTitle: string
  ): Promise<boolean> => {
    setMessage(message);
    setButtonTitle(buttonTitle);
    setIsOpen(true);
    return new Promise<boolean>((resolve) => {
      setResolveReject(() => resolve);
    });
  };

  const handleConfirm = useCallback(() => {
    if (resolveReject) {
      resolveReject(true);
    }
    setIsOpen(false);
  }, [resolveReject]);

  const handleCancel = useCallback(() => {
    if (resolveReject) {
      resolveReject(false);
    }
    setIsOpen(false);
  }, [resolveReject]);

  return (
    <ConfirmationContext.Provider value={{ confirm: requestConfirm }}>
      {children}
      <ConfirmDialog
        isOpen={isOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        message={message}
        buttonTitle={buttonTitle}
      />
    </ConfirmationContext.Provider>
  );
};

export default ConfirmationProvider;
