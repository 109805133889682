import { styled } from "styled-components";
import { Language, LanguageNames } from "../../api/models/common";
import { Check } from "react-bootstrap-icons";

interface Props {
  selectedLanguage: Language;
  onLanguageChange: (language: Language) => void;
  isLanguageDefined: (language: Language) => boolean;
}

const LanguagesContainer = styled.ul`
  display: flex;
  column-gap: 8px;
  row-gap: 12px;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
`;

const LanguageButton = styled.button<{
  $selected?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 8px 12px;
  border: none;
  border-radius: 3vmin;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  cursor: pointer;
  color: ${(props) => (props.$selected ? "#fff" : "#767877")};
  background: ${(props) => (props.$selected ? "#86959F" : "#f0f3f1")};

  transition: background 0.2s ease, color 0.2s ease;

  svg {
    margin: -3px 0 -3px -2px;
  }

  &:hover {
    background: ${(props) => (props.$selected ? "#616f78" : "#d5dbd9")};
  }
`;

const LanguageSelector: React.FC<Props> = (props) => {
  const supportedLanguages = Object.values(Language);
  const onLanguageChange = (e: React.MouseEvent, language: Language) => {
    e.preventDefault();
    props.onLanguageChange(language);
  };

  return (
    <LanguagesContainer>
      {supportedLanguages.map((language) => {
        const languageName = LanguageNames[language];
        const isDefined = props.isLanguageDefined(language);

        return (
          <li key={language}>
            <LanguageButton
              $selected={language === props.selectedLanguage}
              onClick={(e) => onLanguageChange(e, language)}
            >
              {isDefined ? <Check size={16} /> : null} {languageName}
            </LanguageButton>
          </li>
        );
      })}
    </LanguagesContainer>
  );
};

export default LanguageSelector;
