import React from "react";
import logo from "../../assets/logo.png";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Image = styled.img`
  width: 313px;
  height: 100px;
`;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  height: 196px;
  margin-bottom: 48px;
`;

const Header: React.FC = () => {
  return (
    <StyledHeader>
      <Link to="/">
        <Image src={logo} alt="Pixs Admin" />
      </Link>
    </StyledHeader>
  );
};

export default Header;
