import { css, keyframes, styled } from "styled-components";
import LanguageSelector from "../../../../components/language-selector/LanguageSelector";
import useTitleForm from "./use-title-form";
import { Collection } from "../../../../api/models/collection";

interface Props {
  collection?: Collection.Model;
  onCollectionUpdated: () => void;
}

const Form = styled.form`
  background-color: #fff;
  border-radius: 16px;
  padding: 12px;
  margin-bottom: 40px;
`;

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const TitleInput = styled.input<{ $isSaving?: boolean }>`
  padding: 8px 12px;
  margin-bottom: 16px;
  border-radius: 8px;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  line-height: 32px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  transition: all 0.2s ease;

  background-color: #f4f8f6;

  ${(props) =>
    props.$isSaving &&
    css`
      animation: ${blinkAnimation} 1s infinite;
    `}

  &::placeholder {
    color: #a9aeaf;
  }
`;

const LanguagesContainer = styled.div`
  margin: 0px 2px 4px 2px;
`;

const CollectionTitleContainer: React.FC<Props> = (props) => {
  const form = useTitleForm(props.collection);

  return (
    <Form>
      <TitleInput
        type="text"
        value={form.title}
        placeholder="Collection title"
        onChange={form.onTitleChanged}
        $isSaving={form.isSaving}
      />

      <LanguagesContainer>
        <LanguageSelector
          selectedLanguage={form.language}
          onLanguageChange={form.setLanguage}
          isLanguageDefined={form.isLanguageDefined}
        />
      </LanguagesContainer>
    </Form>
  );
};

export default CollectionTitleContainer;
