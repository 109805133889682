import { useCallback, useEffect, useState } from "react";
import { Wallpaper } from "../../../api/models/wallpaper";
import { WallpaperApiClient } from "../../../api/clients/wallpapers-api-client";

export const useWallpaper = (wallpaperId?: string) => {
  const [wallpaper, setWallpaper] = useState<Wallpaper.Model | undefined>();

  const loadWallpaper = useCallback(async () => {
    if (!wallpaperId) return;

    try {
      const wallpapers = await WallpaperApiClient.fetchByIds([wallpaperId]);
      setWallpaper(wallpapers[0]);
    } catch (error) {
      console.error("Error fetching wallpaper:", error);
      alert("Error fetching wallpaper");
    }
  }, [wallpaperId]);

  useEffect(() => {
    loadWallpaper();
  }, [loadWallpaper]);

  return {
    wallpaper,
  };
};
