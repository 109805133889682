import { Wallpaper } from "../../../api/models/wallpaper";
import { WallpaperListOptions } from "../components/WallpaperListOptionsMenu";

export const applyOptions = (
  wallpapers: Wallpaper.Model[],
  options: WallpaperListOptions
) => {
  const filteredWallpapers = [...wallpapers];

  switch (options.sortBy) {
    case "recency":
      filteredWallpapers.sort((a, b) => b.created_at - a.created_at);
      break;
    case "views":
      filteredWallpapers.sort(
        (a, b) => b.analytics.previews - a.analytics.previews
      );
      break;
    case "downloads":
      filteredWallpapers.sort(
        (a, b) => b.analytics.downloads - a.analytics.downloads
      );
      break;
    case "conversion":
      filteredWallpapers.sort(
        (a, b) => b.analytics.conversion - a.analytics.conversion
      );
      break;
  }

  return filteredWallpapers;
};
