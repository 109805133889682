import React from "react";
import Header from "../../components/page-structure/Header";
import NavigationBar from "../../components/page-structure/NavigationBar";
import { Outlet } from "react-router-dom";
import { usePromotions } from "./hooks/use-promotions";
import PromotionsList from "./components/PromotionsList";

const PromotionsPage: React.FC = () => {
  const { promotions, loading, reloadPromotions } = usePromotions();

  return (
    <>
      <Header />
      <NavigationBar />
      <PromotionsList
        loading={loading}
        promotions={promotions}
        onPromotionUpdated={reloadPromotions}
      />
      <Outlet />
    </>
  );
};

export default PromotionsPage;
