import React, { useState } from "react";
import ModalContainer from "../../components/page-structure/ModalContainer";
import { useAuth } from "../../components/auth/AuthContext";
import styled from "styled-components";
import { ReactComponent as Spinner } from "../../assets/spinner.svg";

export const LoginPage: React.FC = () => {
  const { login } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    try {
      await login(username, password);
    } catch (e) {
      setError("Invalid username or password");
      setLoading(false);
    }
  };

  return (
    <ModalContainer maxWidth="512px">
      <Form onSubmit={handleSubmit}>
        <Title>Pixs Content Management</Title>
        <Input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {error && <Error>{error}</Error>}
        <Button type="submit" disabled={loading}>
          {loading ? <Spinner fill="#fff" /> : "Login"}
        </Button>
      </Form>
    </ModalContainer>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -1.5px;
  margin-bottom: 36px;
`;

const Input = styled.input`
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  color: ${(props) => props.theme.colors.primary};
  background: #f0f3f1;
  outline: none;
  margin-bottom: 16px;

  &::placeholder {
    color: #a9aeaf;
  }
`;

const Button = styled.button`
  margin-top: 8px;
  padding: 20px;
  width: 100%;
  background-color: #406e68;
  color: #fff;
  border: none;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 12px;
  cursor: pointer;

  transition: background 0.2s ease;

  &:hover {
    background-color: #2e554f;
  }

  svg {
    margin: -8px;
  }
`;

const Error = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
  color: #e74c3c;
`;
