import styled from "styled-components";
import Tooltip from "../tooltip/Tooltip";

export type TagHighlightStyle = "good" | "bad";

interface Props {
  highlightStyle?: TagHighlightStyle;
  tooltipText?: string;
  children?: React.ReactNode;
}

function resolveTextColor(highlightStyle?: TagHighlightStyle) {
  switch (highlightStyle) {
    case undefined:
      return "#767877";
    case "good":
      return "#11B644";
    case "bad":
      return "#F1554F";
  }
}

const resolveBackgroundColor = (highlightStyle?: TagHighlightStyle) => {
  switch (highlightStyle) {
    case undefined:
      return "#EDF2F1";
    case "good":
      return "#E4F2E4";
    case "bad":
      return "#F5ECEB";
  }
};

const TagContainer = styled.div<{ $highlightStyle?: TagHighlightStyle }>`
  position: relative;
  font-size: 12px;
  font-weight: 620;
  padding: 6px 10px;
  border-radius: 14px;

  color: ${(props) => resolveTextColor(props.$highlightStyle)};
  background: ${(props) => resolveBackgroundColor(props.$highlightStyle)};

  display: flex;
  gap: 6px;
  align-items: center;
`;

export const Tag: React.FC<Props> = (props) => (
  <TagContainer $highlightStyle={props.highlightStyle}>
    {props.children}
    {props.tooltipText && (
      <Tooltip $hostingComponent={TagContainer}>{props.tooltipText}</Tooltip>
    )}
  </TagContainer>
);
