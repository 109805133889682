import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../api/common/api.constants";
import apiClient, {
  setupAuthInterceptor,
} from "../../api/common/axios-instance";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const useJwt = () => {
  const [jwt, setJwt] = useState<string | null>(localStorage.getItem("jwt"));
  return {
    jwt,
    setJwt: (jwt: string | null) => {
      setJwt(jwt);
      if (jwt !== null) {
        localStorage.setItem("jwt", jwt);
      } else {
        localStorage.removeItem("jwt");
      }
    },
  };
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { jwt, setJwt } = useJwt();
  const isAuthenticated = jwt !== null;

  const navigate = useNavigate();

  const login = async (username: string, password: string) => {
    const response = await apiClient.post(`${API_BASE_URL}/auth`, {
      username,
      password,
    });
    setJwt(response.data.jwt);
    navigate("/");
  };

  const logout = useCallback(() => {
    setJwt(null);
    navigate("/login");
  }, [navigate, setJwt]);

  setupAuthInterceptor(logout);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
