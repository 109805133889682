import React from "react";
import ModalContainer from "../../../components/page-structure/ModalContainer";
import { styled } from "styled-components";
import LanguageSelector from "../../../components/language-selector/LanguageSelector";
import { usePromotionModalForm } from "./use-promotion-modal-form";
import { ReactComponent as Spinner } from "../../../assets/spinner.svg";
import { breakpoints } from "../../../styles/theme";
import DatePicker from "../../../components/date-input/DatePicker";
import { useParams } from "react-router-dom";

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -1.5px;
`;

const Content = styled.div<{ $hidden?: boolean }>`
  opacity: ${(props) => (props.$hidden ? 0 : 1)};
  transition: opacity 0.3s;
`;

const Form = styled.form`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Input = styled.input`
  width: 100%;
  padding: 24px;
  border-radius: 8px 12px;
  border: none;
  font-size: 16px;
  color: ${(props) => props.theme.colors.primary};
  background: #f0f3f1;
  outline: none;

  &::placeholder {
    color: #a9aeaf;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 24px;
  border-radius: 8px 12px;
  border: none;
  font-size: 16px;
  color: ${(props) => props.theme.colors.primary};
  background: #f0f3f1;
  outline: none;
  resize: none;

  &::placeholder {
    color: #a9aeaf;
  }
`;

const Stack = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;

  @media (max-width: ${breakpoints.desktop}) {
    grid-template-columns: 1fr;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  gap: 32px;
`;

const SaveButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 32px 12px 32px;
  background-color: #406e68;
  color: #fff;
  border: none;
  font-size: 16px;
  border-radius: 12px;
  cursor: pointer;

  transition: background 0.2s ease;

  &:hover {
    background-color: #2e554f;
  }
`;

const PromotionModal: React.FC = () => {
  const params = useParams<{ promotionId?: string; collectionId?: string }>();
  const form = usePromotionModalForm(params);

  return (
    <ModalContainer closable onClose={form.onClose}>
      <Content $hidden={form.isLoading}>
        <Title>Promote collection "{form.collectionTitle}"</Title>
        <Form onSubmit={form.onSubmit}>
          <Input
            type="text"
            placeholder="Title"
            value={form.title}
            onChange={form.onChangeTitle}
            maxLength={50}
          />
          <TextArea
            placeholder="Description"
            value={form.description}
            onChange={form.onChangeDescription}
            maxLength={150}
          />
          <Stack>
            <DatePicker
              date={form.startDate}
              prefix="Starts"
              onChange={(date) => date && form.setStartDate(date)}
            />
            <DatePicker
              date={form.endDate}
              prefix="Ends"
              onChange={(date) => date && form.setEndDate(date)}
            />
          </Stack>
          <DatePicker
            date={form.sendPushDate}
            prefix="Send push at"
            timePickerEnabled={true}
            onChange={(date) => date && form.setSendPushDate(date)}
          />

          <Footer>
            <LanguageSelector
              selectedLanguage={form.language}
              onLanguageChange={form.setLanguage}
              isLanguageDefined={form.isLanguageDefined}
            />
            <SaveButton type="submit">
              {form.isSaving ? <Spinner fill="#fff" /> : <>Save</>}
            </SaveButton>
          </Footer>
        </Form>
      </Content>
    </ModalContainer>
  );
};

export default PromotionModal;
