import { useCallback, useEffect, useState } from "react";
import { CollectionApiClient } from "../../../api/clients/collections-api-client";
import { Collection } from "../../../api/models/collection";
import { useNavigate } from "react-router-dom";

const useCollection = (collectionId?: string) => {
  const [collection, setCollection] = useState<Collection.Model>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const loadCollection = useCallback(async () => {
    if (!collectionId) return;
    setLoading(true);

    try {
      const collections = await CollectionApiClient.fetchByIds([collectionId]);
      setCollection(collections[0]);
    } catch (error) {
      console.error("Error fetching collection:", error);
    } finally {
      setLoading(false);
    }
  }, [collectionId]);

  useEffect(() => {
    loadCollection();
  }, [loadCollection]);

  const toggleVisibility = async () => {
    if (!collection) return;

    const setIsExplorable = (value: boolean) => {
      const mutatableCollection = { ...collection };
      mutatableCollection.is_explorable = value;
      setCollection(mutatableCollection);
    };

    const initialValue = collection.is_explorable;
    setIsExplorable(!initialValue);

    try {
      await CollectionApiClient.update(collection.id, {
        is_explorable: !collection.is_explorable,
      });
    } catch (error) {
      setIsExplorable(initialValue);

      alert("Error updating collection. Please try again later.");
      console.error("Error updating collection:", error);
    }
  };

  const deleteCollection = async () => {
    if (!collection) return;
    try {
      await CollectionApiClient.drop(collection.id);
      navigate("/");
    } catch (error) {
      console.error("Error deleting collection:", error);
    }
  };

  return {
    collection,
    loading,
    reloadCollection: loadCollection,
    toggleVisibility,
    deleteCollection,
  };
};

export default useCollection;
