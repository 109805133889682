import { Collection } from "../models/collection";
import apiClient from "../common/axios-instance";

interface FetchCollectionsParams {
  limit: number;
}

const fetchAll = async (
  params: FetchCollectionsParams
): Promise<Collection.Model[]> => {
  const endpoint = `/collections`;
  const response = await apiClient.get<Collection.Model[]>(endpoint, {
    params: { limit: params.limit },
  });
  return response.data;
};

const fetchByIds = async (
  ids: Collection.Id[]
): Promise<Collection.Model[]> => {
  const endpoint = `/collections/${ids.join(",")}`;
  const response = await apiClient.get<Collection.Model[]>(endpoint);
  return response.data;
};

const create = async (
  newCollection: Omit<Collection.Model, "id">
): Promise<Collection.Model> => {
  const endpoint = `/collections`;
  const response = await apiClient.post<Collection.Model>(
    endpoint,
    newCollection
  );
  return response.data;
};

const update = async (
  id: Collection.Id,
  updatedCollection: Partial<Collection.Model>
): Promise<Collection.Model> => {
  const endpoint = `/collections/${id}`;
  const response = await apiClient.put<Collection.Model>(
    endpoint,
    updatedCollection
  );
  return response.data;
};

const drop = async (id: Collection.Id): Promise<void> => {
  const endpoint = `/collections/${id}`;
  await apiClient.delete(endpoint);
};

const addWallpapers = async (
  collectionId: string,
  wallpaperIds: string[]
): Promise<void> => {
  const endpoint = `/collections/${collectionId}/wallpapers/add`;
  await apiClient.post(endpoint, { wallpaperIds });
};

export const CollectionApiClient = {
  fetchAll,
  fetchByIds,
  create,
  update,
  drop,
  addWallpapers,
};
