import { useNavigate, useParams } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useWallpaper } from "./use-wallpaper";
import { ReactComponent as Spinner } from "../../../assets/spinner.svg";
import { useState } from "react";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 14, 12, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  animation: ${fadeIn} 0.3s;
  backdrop-filter: blur(32px);
`;

const ImageContainer = styled.div<{ $isFullSize: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: ${(props) => (props.$isFullSize ? "scroll" : "hidden")};
  width: 100%;
  height: 100%;
  position: relative;
`;

const Image = styled.img<{ $isFullSize: boolean }>`
  max-width: 90%;
  max-height: ${(props) => (props.$isFullSize ? "none" : "90%")};
  position: ${(props) => (props.$isFullSize ? "absolute" : "static")};
  top: ${(props) => (props.$isFullSize ? "0" : "auto")};
  cursor: ${(props) => (props.$isFullSize ? "zoom-out" : "zoom-in")};
  object-fit: contain;
  border-radius: 8px;
  box-shadow: 0 64px 88px rgba(0, 0, 0, 0.8);
  animation: ${fadeIn} 0.4s;
`;

export const WallpaperPreviewModal: React.FC = () => {
  const [isFullSize, setIsFullSize] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const params = useParams<{ wallpaperId: string }>();
  const { wallpaper } = useWallpaper(params.wallpaperId);

  const navigate = useNavigate();
  const onClose = () => navigate(-1);

  const pictureUrl = wallpaper?.sizes.original.public_url;
  const toggleSize = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsFullSize(!isFullSize);
  };

  const isLoading = isImageLoading || pictureUrl === undefined;

  return (
    <ModalBackdrop onClick={onClose}>
      <ImageContainer $isFullSize={isFullSize}>
        {isLoading && <Spinner fill="#fff" />}
        {pictureUrl && (
          <Image
            src={pictureUrl}
            alt="Wallpaper Preview"
            $isFullSize={isFullSize}
            onClick={toggleSize}
            onLoad={() => setIsImageLoading(false)}
            onError={() => setIsImageLoading(false)}
            style={{ display: isImageLoading ? "none" : "block" }}
          />
        )}
      </ImageContainer>
    </ModalBackdrop>
  );
};
