import React, { useCallback, useState } from "react";
import { styled } from "styled-components";
import CardContainer from "../../../../components/cards/CardContainer";
import { CloudArrowUpFill, GripHorizontal } from "react-bootstrap-icons";
import { useDropzone } from "react-dropzone";
import CircularProgressBar from "../../../../utils/circular-progress-bar";
import { WallpaperUploader } from "../../../../api/tools/wallpapers-uploader";

interface Props {
  collectionId: string | undefined;
  isExplorable: boolean;
  onWallpapersUpdated: () => void;
}

const StyledCardContainer = styled(CardContainer)`
  display: flex;
  flex: 1;
`;

const Button = styled.button<{ $isDragging: boolean }>`
  border: none;
  cursor: pointer;
  background: none;
  border-radius: 16px;
  border: ${(props) => (props.$isDragging ? "4px solid #edf1f0" : "none")};

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 216px;
`;

const Stack = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

const Text = styled.span`
  color: #86959f;
  font-size: 14px;
  margin: 0px 24px;
`;

const AddWallpaperCard: React.FC<Props> = (props) => {
  const [uploadProgress, setUploadProgress] = useState<number | undefined>(
    undefined
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!props.collectionId) return;

      try {
        await WallpaperUploader.uploadMultiple(
          props.collectionId,
          props.isExplorable,
          acceptedFiles,
          (progress) => {
            setUploadProgress(Math.round(progress * 100));
          }
        );
        props.onWallpapersUpdated();
      } catch (error) {
        alert("Upload failed");
        console.error("Upload failed:", error);
      } finally {
        setUploadProgress(undefined);
      }
    },
    [props]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDragEnter: undefined,
    onDragLeave: undefined,
    onDragOver: undefined,
    multiple: true,
  });

  return (
    <StyledCardContainer {...getRootProps()}>
      <Button $isDragging={isDragActive}>
        <input {...getInputProps()} accept="image/jpeg" multiple />

        {uploadProgress !== undefined ? (
          <Stack>
            <CircularProgressBar
              progress={uploadProgress}
              size={48}
              radius={10}
              backgroundCircleColor="#edf1f0"
              strokeWidth={4}
              strokeColor="#86959F"
            />
            <Text>Uploading... {uploadProgress}%</Text>
          </Stack>
        ) : isDragActive ? (
          <Stack>
            <GripHorizontal size={48} color="#ccd5da" />
            <Text>Drop images to upload</Text>
          </Stack>
        ) : (
          <Stack>
            <CloudArrowUpFill size={48} color="#ccd5da" />
            <Text>
              Drag and drop or click <br /> to upload a picture
            </Text>
          </Stack>
        )}
      </Button>
    </StyledCardContainer>
  );
};

export default AddWallpaperCard;
