import React, { useCallback } from "react";
import { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-bootstrap-icons";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import "./PopupMenu.css";
import { breakpoints } from "../../styles/theme";

interface Props {
  children: (closeMenu: () => void) => React.ReactNode;
}

const PopupMenuContainer = styled.div`
  position: relative;
`;

const StyledPopupMenu = styled.div`
  position: absolute;
  top: 100%;
  width: 168px;
  background: #fff;
  box-shadow: 0 8px 24px rgba(26, 30, 29, 0.12);
  z-index: 10;
  padding: 4px 0;
  max-height: 300px;
  overflow-y: auto;

  @media (min-width: ${breakpoints.desktop}) {
    left: -16px;
  }

  @media (max-width: ${breakpoints.desktop}) {
    right: -16px;
  }
`;

const ActionsButton = styled.button`
  margin-top: 2px;
  color: #bcc5c4;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: #767877;
  }
`;

export const PopupMenu: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const closeMenu = () => setIsOpen(false);

  const togglePopover: React.MouseEventHandler = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      closeMenu();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  const nodeRef = useRef(null);

  return (
    <PopupMenuContainer ref={containerRef}>
      <ActionsButton onClick={togglePopover}>
        <ThreeDots size={24} />
      </ActionsButton>
      <CSSTransition
        in={isOpen}
        nodeRef={nodeRef}
        timeout={100}
        classNames="menu-popover"
        unmountOnExit
      >
        <StyledPopupMenu ref={nodeRef}>{children(closeMenu)}</StyledPopupMenu>
      </CSSTransition>
    </PopupMenuContainer>
  );
};
