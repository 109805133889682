import { Trash2Fill } from "react-bootstrap-icons";
import { PopupMenu } from "../../../../components/menu-popover/PopupMenu";
import { PopupMenuButton } from "../../../../components/menu-popover/PopupMenuButton";
import { ReactNode } from "react";
import { useConfirmation } from "../../../../components/confirm-dialog/ConfirmationContext";
import { PromotionsApiClient } from "../../../../api/clients/promotions-api-client";

interface Props {
  promotionId: string;
  onPromotionUpdated: () => void;
}

type MenuContent = (closeMenu: () => void) => ReactNode;

export const PromotionCardActions: React.FC<Props> = (props) => {
  const confirmation = useConfirmation();

  const content: MenuContent = (closeMenu) => {
    const onDelete = async () => {
      closeMenu();

      const confirmDelete = await confirmation.confirm(
        "Are you sure?",
        "Delete promotion"
      );

      if (!confirmDelete) {
        return;
      }

      try {
        await PromotionsApiClient.drop(props.promotionId);
        props.onPromotionUpdated();
      } catch (error) {
        console.error("Error deleting promotion:", error);
        alert("Failed to delete promotion.");
      }
    };

    return (
      <>
        <PopupMenuButton isDestructive={true} onClick={onDelete}>
          <Trash2Fill size={12} />
          Delete
        </PopupMenuButton>
      </>
    );
  };

  return <PopupMenu>{content}</PopupMenu>;
};
