import { useState } from "react";
import { styled } from "styled-components";

const ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 9 / 16;
  overflow: hidden;
  margin-bottom: 24px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    border: 1px solid #e8edf3;
  }
`;

const ErrorMessage = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-color: #f4f8f6;
  border-radius: 12px;
  font-size: 14px;
  color: #acb5b1;
`;

const WallpaperPreviewImage: React.FC<{ url: string }> = (props) => {
  const [imageError, setImageError] = useState(false);
  const [retryKey, setRetryKey] = useState(0);

  const handleLoad = () => {
    setImageError(false);
  };

  const handleError = () => {
    setImageError(true);
    setTimeout(() => {
      setRetryKey((prevKey) => prevKey + 1);
    }, 3000);
  };

  return (
    <ImageContainer>
      {imageError && <ErrorMessage>Preview unavailable</ErrorMessage>}
      <img
        key={retryKey}
        src={props.url}
        alt="Wallpaper"
        onLoad={handleLoad}
        onError={handleError}
        style={{ display: imageError ? "none" : "block" }}
      />
    </ImageContainer>
  );
};

export default WallpaperPreviewImage;
