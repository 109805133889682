import axios from "axios";
import { API_BASE_URL } from "./api.constants";

const getAuthHeader = () => {
  const token = localStorage.getItem("jwt");
  return token ? `Bearer ${token}` : "";
};

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    config.headers.Authorization = getAuthHeader();
    return config;
  },
  (error) => Promise.reject(error)
);

let isAuthInterceptorSetup = false;

const setupAuthInterceptor = (logout: () => void) => {
  if (isAuthInterceptorSetup) {
    return;
  }

  isAuthInterceptorSetup = true;
  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );
};

export default apiClient;
export { setupAuthInterceptor };
