import React from "react";
import styled, { css } from "styled-components";
import { RuleSet } from "styled-components/dist/types";

type ColumnStyle = "singleColumn" | "twoColumns" | "threeColumns";

interface Props {
  columnStyle?: ColumnStyle;
  children?: React.ReactNode;
}

const gridConfiguration: { [key in ColumnStyle]: RuleSet<object> } = {
  singleColumn: css`
    grid-template-columns: 1fr;
  `,
  twoColumns: css`
    grid-template-columns: repeat(auto-fill, minmax(367px, 1fr));
  `,
  threeColumns: css`
    grid-template-columns: repeat(auto-fill, minmax(264px, 1fr));
  `,
};

const StyledGrid = styled.ul<{ $style?: ColumnStyle }>`
  display: grid;
  grid-gap: 16px;

  ${(props) => props.$style && gridConfiguration[props.$style as ColumnStyle]}

  li {
    list-style: none;
  }
`;

const CardsList: React.FC<Props> = ({ columnStyle, children }) => {
  return <StyledGrid $style={columnStyle}>{children}</StyledGrid>;
};

export default CardsList;
