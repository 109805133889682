import { useEffect, useState } from "react";
import { PromotionsApiClient } from "../../../api/clients/promotions-api-client";
import { PromotionCardModel } from "../components/PromotionCard";
import makePromotionCardModel from "./promotion-card-model-factory";
import { useLocation } from "react-router-dom";
import { CollectionApiClient } from "../../../api/clients/collections-api-client";

export const usePromotions = () => {
  const location = useLocation();
  const [promotions, setPromotions] = useState<PromotionCardModel[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPromotions();
  }, []);

  useEffect(() => {
    if (location.state?.reload) {
      loadPromotions();
      window.history.replaceState({}, "");
    }
  }, [location.state]);

  const loadPromotions = async () => {
    try {
      setLoading(true);
      const promotions = await PromotionsApiClient.fetchAll();
      const collectionIds = promotions.map((p) => p.collection_id);
      const collections = await CollectionApiClient.fetchByIds(collectionIds);
      const collectionsMap = new Map(collections.map((c) => [c.id, c]));

      const promotionCardModels = promotions.flatMap((p) => {
        const collection = collectionsMap.get(p.collection_id);
        if (!collection) return [];

        return makePromotionCardModel(p, collection);
      });
      setPromotions(promotionCardModels);
    } catch (error) {
      alert("Error loading promotions. Please try again later.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return { promotions, loading, reloadPromotions: loadPromotions };
};
