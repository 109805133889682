import axios from "axios";

const s3Client = axios.create();

const putToS3 = async (
  url: string,
  file: File,
  onUploadProgress?: (progress: number) => void
): Promise<void> => {
  await s3Client.put(url, file, {
    onUploadProgress: ({ loaded, total }) => {
      if (onUploadProgress) {
        const progress = loaded / (total || 1);
        onUploadProgress(progress);
      }
    },
  });
};

export const S3ApiClient = {
  putToS3,
};
