import { WallpaperApiClient } from "../../../../api/clients/wallpapers-api-client";
import { useConfirmation } from "../../../../components/confirm-dialog/ConfirmationContext";

interface Props {
  collectionId: string;
  wallpaperId: string;
  onWallpaperUpdated: () => void;
}

const useWallpaperActions = (props: Props) => {
  const confirmation = useConfirmation();

  const handleDelete = async (dismiss: () => void) => {
    dismiss();

    const confirmDelete = await confirmation.confirm(
      "Are you sure?",
      "Delete wallpaper"
    );

    if (!confirmDelete) {
      return;
    }

    try {
      await WallpaperApiClient.drop({
        collectionId: props.collectionId,
        wallpaperId: props.wallpaperId,
      });
      props.onWallpaperUpdated();
    } catch (error) {
      console.error("Error deleting wallpaper:", error);
      alert("Error deleting wallpaper. Please try again later.");
    } finally {
      dismiss();
    }
  };

  return {
    handleDelete,
  };
};

export default useWallpaperActions;
